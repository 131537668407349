import React, {useEffect} from "react";
import { Button, DatePicker, Layout, Result, Skeleton, Space } from "antd";
import moment from 'moment';
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import useDemand from 'app/Hooks/useDemand';
import { IModel, TPredictionModel } from "app/typings";
import Scaffold from 'app/__portions/Scaffold';
import { spaceRemover } from "app/utils/helpers/espaceRemover";
import DemandPlanningTable from './DemandPlanningTable';
import DemandPlanningChart from './Chart';
import ModalCustomization from './ModelCustomization';
import LocalStorage from "../../utils/helpers/LocalStorage";
import { TreeDataState } from "../../bloc/atoms";
import { getGranularityDateFormat } from "../../utils/helpers";

const DemandPlanning = () => {
  const {
    hasLoaded,
    saving,
    loading,
    currentCycle,
    data: planningData,
    getPlanningForecast,
    onSelect,
    onSave,
    onRefreshData,
    onCycleChange,
    onPreviousCycle,
  } = useDemand();

  const { treeData } = useRecoilValue(TreeDataState);

  useEffect(() => {
    if (!hasLoaded) {
      if(LocalStorage.getTreeSelection() !== null && LocalStorage.getSelectedTreeKey() !== null){
        // @ts-ignore
        getPlanningForecast(JSON.parse(LocalStorage.getTreeSelection()))
      } else {
        getPlanningForecast()
      }
    }
  }, [getPlanningForecast, hasLoaded]);

  const horizon = planningData!.columns?.map((data) => (data.title))

  const onDemandExport = () => {
    try {
      const plannerConsensusMap = (horizon: string) => {
        const data = planningData?.consensus?.plannerConsensus?.map((i) => i)
        if(!data) return

        const forecast = Object.keys(data.map((d) => d.forecast)).map((date) => {
          const name = data.map((a) => a.name)
          return {
            [`(P)-${name[date]}`]: planningData?.consensus?.plannerConsensus?.map((i) => i.forecast)[date][horizon] || "-"
          }
        })
        // eslint-disable-next-line consistent-return
        return Object.keys(forecast).reduce((acc, key) => {
          const [innerKey, value] = Object.entries(forecast[key])[0];
          acc[innerKey] = value;
          return acc;
        }, {})
      }

      const salesConsensusMap = (horizon: string) => {
        const data = planningData?.consensus?.salesConsensus?.map((i) => i)
        // @ts-ignore
        const forecast = Object.keys(data.map((d) => d.forecast)).map((date) => {
          const name = data.map((a) => a.name)
          return {
            [`(S)-${name[date]}`]: planningData?.consensus?.salesConsensus?.map((i) => i.forecast)[date][horizon] || "-"
          }
        })

        return Object.keys(forecast).reduce((acc, key) => {
          const [innerKey, value] = Object.entries(forecast[key])[0];
          acc[innerKey] = value;
          return acc;
        }, {})
      }

      const wsData = horizon!.map((date) => ({
        Date        : date,
        Actual      : planningData?.actual?.[date]                    ?? '-',
        ActualAdjts : planningData?.adjustments?.actualAdjts?.[date]  ?? '-',
        PlannerAdjts: planningData?.adjustments?.plannerAdjts?.[date] ?? '-',
        SalesAdjts  : planningData?.adjustments?.salesAdjts?.[date]   ?? '-',
        Forecast    : planningData?.model?.forecast?.[date]           ?? '-',
        Accuracy    : planningData?.model?.accuracy?.[date]           ?? '-',
        ...plannerConsensusMap(date),
        ...salesConsensusMap(date),
      })).filter(entry => Object.values(entry).every(value => value !== undefined));

      const ws= XLSX.utils.json_to_sheet(wsData);
      const wb= XLSX.utils.book_new();

      XLSX.utils.sheet_add_aoa(ws, [["PRODUCT:"]], { origin: 'AA2' });
      XLSX.utils.sheet_add_aoa(ws, [[spaceRemover(LocalStorage.getSelectedTreeKey())]], { origin: 'AB2' });

      XLSX.utils.sheet_add_aoa(ws, [["CYCLE:"]], { origin: 'AA3' });
      XLSX.utils.sheet_add_aoa(ws, [[currentCycle]], { origin: 'AB3' });

      XLSX.utils.sheet_add_aoa(ws, [["HORIZON:"]], { origin: 'AA4' });
      XLSX.utils.sheet_add_aoa(ws, [[LocalStorage.getHorizon()]], { origin: 'AB4' });

      XLSX.utils.book_append_sheet(wb, ws, `Demand Planning`);

      const payload = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      saveAs(new Blob([payload], { type: "application/octet-stream" }), `Demand-Planning-Cycle-${currentCycle}.xlsx`);
    } catch (e) {
      console.error(e)
    }
  };

  const onPredictionChange = (predictionModel: TPredictionModel) => {
    const model = planningData.models?.find(
      (model) => predictionModel === model.name,
    );

    if (model) {
      onRefreshData(
        planningData.actual,
        planningData.externalForecast,
        model,
        planningData.adjustments,
        planningData.consensus,
        planningData.models as IModel[],
      );
    }
  };

  return (
    <Scaffold onSelect={onSelect}>
      {(hasLoaded && treeData.length === 0 && planningData.rowData?.length) && (
        <Layout>
          <div className="p-2 md:p-4 pb-16 overflow-y-auto w-full">
            <Result
              status="404"
              title='Empty Data'
              subTitle="Sorry, it seems there is no data found. Please try again later..."
              extra={
                <Link to="/" type="primary">
                  Refresh Page
                </Link>
              }
            />
          </div>
        </Layout>
      ) || (
        <>
          <div className="ant-row ant-row-space-between ant-space-align-center">
            <h1 className="text-lg font-bold letter-28 text-black">
              DEMAND PLANNING
            </h1>
            <Space size="large">
              <div>
                <div className="text-xs mb-1 text-black-50">Export</div>
                <Button
                  className="rounded-sm border-w-2 border-gray-300 btn-height"
                  style={{ minWidth: '150px' }}
                  onClick={() => onDemandExport()}
                >Export excel</Button>
              </div>
              <div>
                <div className="text-xs mb-1 text-black-50">Cycle</div>
                <DatePicker
                  className="rounded-sm border-w-2 border-gray-300 btn-height"
                  style={{ minWidth: '150px' }}
                  defaultValue={moment(currentCycle)}
                  allowClear={false}
                  onChange={(_, value) => {
                    onCycleChange(value);
                  }}
                />
              </div>
            </Space>
          </div>


          <div className="bg-white shadow-sm rounded-md p-5 my-5">
            <div className="py-2">
              <DemandPlanningTable
                columns={planningData.columns}
                data={planningData.rowData}
                saving={saving}
                loading={loading}
                isPastCycle={moment(currentCycle).isBefore(moment().format(getGranularityDateFormat()))}
                onSave={onSave}
                onPreviousCycle={onPreviousCycle}
              />
            </div>

            <div className="flex-1 ant-col w-full">
              {(loading) ? (<Skeleton/>) : (
                <ModalCustomization onPredictionChange={onPredictionChange} />
              )}
              {(loading) ? (<Skeleton/>) : (
                <DemandPlanningChart data={planningData.graphData} loading={loading} />
              )}
            </div>
          </div>
        </>
      )}
    </Scaffold>
  );
};

export default DemandPlanning
