import {
  TErrorMeasure,
  TPerformanceCheckers,
  TPredictionModel,
  TUserRoles,
} from 'app/typings';

export const PREVIOUS_HISTORY = [3, 6, 12, 18, 24];
export const LAG_SELECTION = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const ERROR_MEASURES: Record<
  TErrorMeasure,
  { name: string; value: string }
> = {
  RMSE: { name: 'RMSE', value: 'rmse' },
  MAPE: { name: 'MAPE', value: 'mape' },
  wMAPE: { name: 'wMAPE', value: 'wmape' },
};

export const PREDICTION_MODELS: Record<
  TPredictionModel,
  { name: string; value: string }
> = {
  MA: {
    name: 'Moving Average (MA)',
    value: 'ma',
  },
  ARIMA: {
    name: 'Autoregressive Integrated Moving Average (ARIMA)',
    value: 'arima',
  },
  SARIMA: {
    name: 'Seasonal Autoregressive Integrated Moving Average (SARIMA)',
    value: 'sarima',
  },
  ES: {
    name: 'Exponential Smoothing (ES)',
    value: 'es',
  },
  HWA: {
    name: 'Holt Winters additive (HWA)',
    value: 'hwa',
  },
  HWM: {
    name: 'Holt Winters multiplicative (HWM)',
    value: 'HWM',
  },
  SES: {
    name: '',
    value: '',
  },
  'C-TSP': {
    name: '',
    value: '',
  },
};

export const PERFORMANCE_CHOICES: Record<TPerformanceCheckers, { name: string; value: string }> = {
  Planner: {
    name: 'Planner',
    value: 'Planner',
  },
  Sales: {
    name: 'Sales',
    value: 'Sales',
  },
  Machine: {
    name: 'Machine',
    value: 'Machine',
  },
};

export const USER_ROLES: Record<TUserRoles, { label: string; value: string }> = {
  Admin  : { label: 'Admin'  , value: 'AD' },
  Planner: { label: 'Planner', value: 'PL' },
  Sales  : { label: 'Sales'  , value: 'SA' },
};

export const DAILY_DATE_FORMAT = 'YYYY-MM-DD';
export const DAILY_SHORT_DATE_FORMAT = 'YY-MM-DD';
export const DAILY_DISPLAY_FORMAT = 'YYYY-MMM-DD';
export const DAILY_SHORT_DISPLAY_FORMAT = 'YY-MMM-DD';
export const WEEKLY_DATE_FORMAT = 'MM-DD';
export const WEEKLY_DISPLAY_FORMAT = 'MMM-DD';
export const MONTHLY_DATE_FORMAT = 'YYYY-MM';
export const MONTHLY_DISPLAY_FORMAT = 'YYYY-MMM';

/// UNIT OF MEASUREMENT
/// 0 - HOURS, 1 - MINUTES and 2 - SECONDS
export const UNIT_MEASURES = ['Hours', 'Minutes', 'Seconds'];
