import React, {
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Input, Form, message } from "antd";
import { FormInstance } from 'antd/lib/form';
import { TAny } from 'app/typings';

import './style.css';

const EditableContext =
  React.createContext<FormInstance<TAny> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

export const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Record<keyof Item, TAny>;
  requireMessage: string;
  required?: boolean;
  loading?: boolean;
  handleSave: (record: TAny) => Promise<void>;
}

export const EditableCell= ({
  title,
  editable,
  children,
  dataIndex,
  record,
  requireMessage,
  required,
  loading,
  handleSave,
  ...restProps
}: EditableCellProps) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      // @ts-ignore
      inputRef.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (loading) {
      form.resetFields();
      setEditing(false);
    }
  }, [form, loading]);

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        setEditing(false);
      }
    });
  }, []);

  const toggleEdit = () => {
    const value = record[dataIndex]?.value || record[dataIndex];
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: value
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      const lastAdjustedCell = Object.keys(values).slice(-1)[0];

      if(values[lastAdjustedCell] === null || values[lastAdjustedCell] === undefined || values[lastAdjustedCell] === "" || values[lastAdjustedCell] === 0) {
        message.info(`The Adjustment on ${lastAdjustedCell} is ignored`)
        setEditing(false);
        return
      }

      const adjustments = {
        [lastAdjustedCell]: {
          value  : values[lastAdjustedCell],
          message: 'N/A'
        },
      };

      toggleEdit();
      await handleSave({ key: record.key, ...adjustments });
      // eslint-disable-next-line consistent-return
      return { ...record, ...values };
    } catch (errInfo) {
      console.error(errInfo)
      // eslint-disable-next-line consistent-return
      return {};
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required,
            message: `${requireMessage || title} is required.`,
          },
        ]}
      >
        <Input
          // @ts-ignore
          ref={inputRef}
          autoComplete="off"
          onBlur={save}
          onPressEnter={save}
          min={1}
          type="number"
          style={{ maxWidth: 120 }}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 30,
          maxWidth: 120,
          overflow: 'hidden',
        }}
        onClick={toggleEdit}
        onKeyDown={() => null}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

EditableCell.defaultProps = {
  required: false,
  loading: false,
};
