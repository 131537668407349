import { FiCalendar } from "react-icons/fi";
import { Col } from "antd";
import { BarLoader } from "react-spinners";
import LocalStorage from "../../../utils/helpers/LocalStorage";
import { PropGranularity } from "../../../typings";

const CardGranularity = ({icon, title, index, onSelect, selected, loading }:PropGranularity) => {
  return (
    <Col xs={24} md={12} lg={8} onClick={() => onSelect ? onSelect(index!) : null} >
      <div className={`display-grid-item flex-col p-3 flex-center rounded-md hover:text-blue ${(!loading && selected) ? 'border-2-blue selected-text-blue bg-blue-transparent' : 'border bg-primary'}`}>
        <div className="relative">
          {icon}
          {(loading && selected) && (
            <BarLoader color="blue" height={4} width={72} loading />)
          }
        </div>
        <span className='font-bold text-lg mt-3 mb-1'>{title}</span>
      </div>
    </Col>
  )
}

CardGranularity.defaultProps = {
  icon    : <FiCalendar size="72px" />,
  title   : 'Daily',
  format  : 'dd-mm-yyyy',
  index   : LocalStorage.getGranularity(),
  selected: false,
  loading : false,
  onSelect: () => null,
};

export default CardGranularity