import { IoAnalyticsSharp } from 'react-icons/io5';
import React from 'react';
import { Row } from 'antd';
import SwitcherIcon from 'app/__portions/SwitcherIcon';
import { TAny } from "app/typings";

export const toTreeModel = (line) => ({
  switcherIcon: () => <SwitcherIcon color="#0051b6" />,
  key: `${line.name}`,
  title: line.name,
  name: line.name,
  selected: line.selected,
  type: 'line',
  children: line.regions.map((region) => ({
    switcherIcon: () => <SwitcherIcon color="#006988" />,
    title: region.name,
    key: `${line.name}_${region.name}`,
    name: region.name,
    lineName: line.name,
    selected: region.selected,
    type: 'region',
    children: region.groups.map((group) => ({
      switcherIcon: () => <SwitcherIcon color="#00b4d8" />,
      key: `${line.name}_${region.name}_${region.name}_${group.name}`,
      title: group.name,
      name: group.name,
      selected: group.selected,
      type: 'group',
      lineName: line.name,
      regionName: region.name,
      children: group.categories.map((category) => ({
        switcherIcon: () => <SwitcherIcon color="#80c6d3" />,
        key: `${line.name}_${region.name}_${group.name}_${category.name}`,
        title: category.name,
        name: category.name,
        selected: category.selected,
        type: 'category',
        lineName: line.name,
        regionName: region.name,
        children: category.items.map((item) => ({
          switcherIcon: () => <SwitcherIcon color="#90e0ef" />,
          key: `${line.name}_${region.name}_${group.name}_${category.name}_${item.name}`,
          title: item.name,
          name: item.name,
          selected: item.selected,
          type: 'item',
          lineName: line.name,
          regionName: region.name,
        })),
      })),
    })),
  })),
});

export const allProductsNode = {
  title: (
    <Row justify="space-between" align="middle">
      <div>All Sales</div>
      <IoAnalyticsSharp size="16px" />
    </Row>
  ),
  key: 'All-products',
  selected: false,
  type: 'all-products',
  name: 'All Sales',
  switcherIcon: () => <SwitcherIcon />,
} as TAny;
