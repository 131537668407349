import React, { Key, useEffect } from "react";
import { Skeleton, Space, Tree } from 'antd';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from "recoil";

import { TAny } from "app/typings";
import { TreeDataState } from "app/bloc/atoms";
import useTreeData from 'app/Hooks/useTreeData';
import LocalStorage from "../../utils/helpers/LocalStorage";
import paths from "../../routes/paths";

interface Props {
  onSelect?: (keys: Key[], info: TAny) => void;
  onExpand?: (keys: Key[], info: TAny) => void;
}

const TreeNavigator = ({ onSelect, onExpand }: Props) => {
  const { treeData, loading, hasLoaded } = useRecoilValue(TreeDataState);
  const [, fetchTreeData] = useTreeData();
  const location = useLocation();

  // @ts-ignore
  const selectedKey = JSON.parse(LocalStorage.getSelectedTreeKey())
  // @ts-ignore
  const expandedKey = JSON.parse(LocalStorage.getExpandedTreeKey())

  const defaultSelected = (selectedKey === null || selectedKey === '') ?
    [
      treeData?.[0]?.
        children?.[0]?.
        children?.[0]?.
        children?.[0]?.
        children?.[0]?.
        children?.[0].key as string
    ] :
    selectedKey;

  const defaultExpanded = (expandedKey === null || expandedKey === '') ?
    [treeData?.[0]?.
      children?.[0]?.
      children?.[0]?.
      children?.[0]?.
      children?.[0].key as string
    ] :
    expandedKey;

  const onSelectTree = (keys: Key[], info: TAny) => {
    onSelect?.(keys, info);
  };

  const onExpandTree = (keys: Key[], info: TAny) => {
    LocalStorage.setExpandedTreeKey(JSON.stringify(keys))
    onExpand?.(keys, info);
  };

  useEffect(() => {
    if (!hasLoaded) {
      fetchTreeData();
    }
  }, [fetchTreeData, hasLoaded]);

  return (
    <div className="py-4 px-1">
      {(location.pathname === paths.DEMAND_PLANNING) && (
        <div className="ant-row ant-space-align-center">
          <div style={{ width: '10px', height: '10px', borderRadius: '10px', backgroundColor: 'green' }} />
          <div style={{ marginLeft: '0.5rem' }}>Demand Planning</div>
        </div>
      )}
      {(loading || treeData.length === 0) ? (
        <>
          <Space className="mb-2">
            <Skeleton.Input style={{ width: 24, borderRadius: 8 }} active size="small"/>
            <Skeleton.Input style={{ width: 175, borderRadius: 8 }} active size="small"/>
          </Space>
          {Array.from({ length: 15 }).map((_, index) => (
            <Space key={index.toFixed()} className="mb-2">
              <Skeleton.Input style={{ width: 24, marginLeft: 20, borderRadius: 8 }} active size="small"/>
              <Skeleton.Input style={{ width: 100, borderRadius: 8 }} active size="small"/>
            </Space>
          ))}
        </>
      ) : (
        <Tree
          showLine
          blockNode
          treeData={treeData}
          defaultSelectedKeys={defaultSelected}
          defaultExpandedKeys={defaultExpanded}
          onSelect={onSelectTree}
          onExpand={onExpandTree}
        />
      )}
    </div>
  );
};

TreeNavigator.defaultProps = {
  onSelect: undefined,
  onExpand: undefined,
};

export default TreeNavigator;
