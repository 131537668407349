import { Layout, Result, Select, Skeleton, Space } from "antd";
import React, { Key, useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  ErrorsState,
  ForecastReportType,
  ForecastSelectionType,
  HorizonRangeDataState,
  HorizonRangeState,
  LagSelectionState,
  ReportDataState,
  ReportTableData,
  selectedKeysState,
  TreeDataState
} from "app/bloc/atoms";
import {
  getActualRow,
  getColumnRanges,
  getFetchDataTreePayload, getForecastRow,
  getGranularityDay, getHistoryRows, getLeadRows, getMadeOnDays, getMetricRows,
  getSelectedGranularityValue
} from "app/utils/helpers";
import ENDPOINTS from "app/Services/endpoints";
import ForecastHorizon from "app/__portions/Selectors/ForecastHorizon";
import Api from "app/Services";
import { IResponseAccuracyData, IResponseWaterfallData, RowType, TAny } from "app/typings";
import Scaffold from "app/__portions/Scaffold";
import {
  PREVIOUS_HISTORY
} from "app/utils/helpers/constants";
import ForecastReport from "./ForecastReport";
import LocalStorage from "../../utils/helpers/LocalStorage";
import { groupByMonth, groupByWeek } from "../Settings/Utils/WeekDataConverter";

const { Option } = Select;

const Report = () => {
  const printRef = useRef<HTMLDivElement>(null)
  const [reportData, setReportData] = useRecoilState(ReportTableData);
  const setErrorState = useSetRecoilState(ErrorsState);
  const treeDataState  = useRecoilValue(TreeDataState);
  const horizonRange   = useRecoilValue(HorizonRangeDataState);
  const [, setHorizon] = useRecoilState(HorizonRangeState);
  const [{ loading, hasLoaded }, setReport] = useRecoilState(ReportDataState);

  const [, setSelectedKeys] = useRecoilState(selectedKeysState);
  const forecastSelection = useRecoilValue(ForecastSelectionType);
  const forecastType = useRecoilValue(ForecastReportType);

  const lagSelection = useRecoilValue(LagSelectionState);

  const [lag, setLag] = useState('');
  const [responseBody, setResponseBody] = useState<IResponseAccuracyData|IResponseWaterfallData>();

  const [historyLength, setHistoryLength] = useState(3);

  const fetchData = useCallback(async (lines:Record<string, TAny>[] = treeDataState.treeDataResponse) => {
        const payload = {
          lines,
          start_date    : horizonRange[0],
          end_date      : horizonRange[1],
          report        : forecastType,
          lag_number    : lagSelection,
          forecast_type : forecastSelection,
        };

        setReport((prev) => {
          return { ...prev, loading: true };
        });

        const { data: responseData, status, message } = await Api.post(
          ENDPOINTS.FETCH_DATA,
          payload
        );

        if (status === 404 || status === 500 || message === "Please try again") {
          setErrorState((errors) => ({
            ...errors,
            aggregationError: {error: 'An error occurred', message},
            isServerDown: true,
          }));
          return;
        }

        if (responseData) {
          const columnsRange = getColumnRanges(horizonRange[0], horizonRange[1])

          const { forecast, actual, metrics, leads } = responseData as IResponseAccuracyData|IResponseWaterfallData;

          setLag(columnsRange[0])
          setResponseBody(responseData)
          setHorizon(columnsRange)

          const madeOnStartDay = moment(horizonRange[0]).subtract(lagSelection, getGranularityDay());
          const madeOnEndDay   = moment(horizonRange[1]).subtract(lagSelection, getGranularityDay());

          const madeOnDays   = getMadeOnDays(madeOnStartDay, madeOnEndDay)
          const actualRow    = getActualRow(actual)
          const forecastRows = getForecastRow(forecast, columnsRange, madeOnDays);
          const historyRows  = getHistoryRows(forecast)
          const leadRow      = getLeadRows(leads)
          const metricRows   = getMetricRows(
              forecastType,
              horizonRange[0],
              lagSelection,
              metrics,
              columnsRange,
          )

          setReport((prev) => {
            return { ...prev, loading: false };
          });

          // @ts-ignore
          setReportData((prevData) => ({
            ...prevData,
            actualRow,
            forecastRows,
            historyRows,
            leadRow,
            metricRows: leadRow ? metricRows : undefined,
            dataLoaded: true,
            forecastEvol: forecast,
          }));
        }
      },
      [
        treeDataState.treeDataResponse,
        horizonRange,
        forecastSelection,
        forecastType,
        setReport,
        lagSelection,
        setReportData,
      ],
  );

  useEffect(() => {
    if (reportData.dataLoaded && historyLength) {
      const historyRows = {
        key: 'forecastHistory bg-gray',
        reportTitle: (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
              <Space>
                <span style={{ whiteSpace: 'nowrap' }}>
                  Previous Forecasts
                </span>
                <Select onChange={setHistoryLength} defaultValue={historyLength}>
                  {PREVIOUS_HISTORY.map((size) => (
                      <Option key={size} value={size}>{size}</Option>
                  ))}
                </Select>
              </Space>
          </div>
        ),
        children: reportData.historyRows?.slice(0, historyLength),
      };

      const data = [] as RowType[];

      data.push(reportData.actualRow);

      if (forecastType === 'over_history' && reportData.historyRows.length) {
        data.push(historyRows);
      }

      let dataRow:TAny;
      let valueRow:TAny;

      if (forecastType === 'over_history') {
        data.push(...reportData.forecastRows);
      } else {
        switch (getSelectedGranularityValue()) {
          case "daily":
            data.push({ key: 'forecast highlight', reportTitle: 'Forecasts', ...reportData.forecastEvol });
            break;
          case "weekly":
            dataRow = groupByWeek(reportData.forecastEvol)
            valueRow = dataRow.reduce((acc: TAny, item: TAny) => {
              const key = Object.keys(item)[0];
              acc[key] = item[key];
              return acc;
            }, {});

            data.push({ key: 'forecast highlight', reportTitle: 'Forecasts', ...valueRow });
            break;
          case "monthly":
            dataRow = groupByMonth({data: reportData.forecastEvol})
            valueRow = dataRow.reduce((acc: TAny, item: TAny) => {
              const key = Object.keys(item)[0];
              acc[key] = item[key];
              return acc;
            }, {});

            data.push({ key: 'forecast highlight', reportTitle: 'Forecasts', ...valueRow });
            break;
          default: data.push({ key: 'forecast highlight', reportTitle: 'Forecasts', ...reportData.forecastEvol });
        }
      }

      data.push({ key: 'empty' });

      if (forecastType === 'over_items') {
        data.push({
          key: 'currentMetricDay',
          reportTitle: `Metrics for lag ${lagSelection}`,
          className: 'text-gray text-xs',
        });
      }

      if (reportData.leadRow && forecastType === 'over_history') {
        data.push({
          key: 'currentMetricDay',
          reportTitle: `Metrics for ${lag}`,
          className: 'text-gray text-xs',
        });
      }

      if (reportData.leadRow && forecastType === 'over_history') {
        data.push(reportData.leadRow);
      }

      if (reportData.metricRows) {
        switch (getSelectedGranularityValue()) {
          case "daily": data.push(...reportData.metricRows);
            break;
          case "weekly":
            data.push(...reportData.metricRows);
            break;
          case "monthly": data.push(...reportData.metricRows);
            break;
          default: data.push(...reportData.metricRows);
        }
      }

      setReport((preValue) => {
        return {
          ...preValue,
          data,
          historyLoaded: true,
          loading: false,
          hasLoaded: true,
        };
      });
    }
  }, [reportData, historyLength, forecastType, setReport, lag, lagSelection]);

  useEffect(() => {
    if (!hasLoaded && horizonRange.length && treeDataState.treeDataResponse.length) {
      if(LocalStorage.getTreeSelection() !== null && LocalStorage.getSelectedTreeKey() !== null){
        // @ts-ignore
        fetchData(JSON.parse(LocalStorage.getTreeSelection()))
      } else {
        fetchData()
      }
    }
  }, [
    horizonRange.length,
    hasLoaded,
    fetchData,
    treeDataState.treeDataResponse,
  ]);

  const onSelect = (keys: Key[], info: TAny) => {
    setSelectedKeys((prev) => ({ ...prev, reportKeys: keys }))

    const payload = getFetchDataTreePayload(
        info.node,
        treeDataState.treeDataResponse
    )

    LocalStorage.setTreeSelection(JSON.stringify(payload))
    LocalStorage.setSelectedTreeKey(JSON.stringify(keys))

    fetchData(payload)
  }

  const onOpenChange = (open: boolean) => {
    if (horizonRange.length && treeDataState.treeDataResponse.length) {
      if (!open) {
        if(LocalStorage.getTreeSelection() !== null && LocalStorage.getSelectedTreeKey() !== null){
          // @ts-ignore
          fetchData(JSON.parse(LocalStorage.getTreeSelection()))
        } else {
          fetchData()
        }
      }
    }
  }

  return (
      <Scaffold onSelect={onSelect}>
        {(hasLoaded && treeDataState.treeData.length === 0) && (
            <Layout>
              <div className="p-2 md:p-4 pb-16 overflow-y-auto w-full">
                <Result
                    status="404"
                    title='Empty Data'
                    subTitle="Sorry, it seems there is no data found. Please try again later..."
                    extra={
                      <Link to="/" type="primary">
                        Refresh Page
                      </Link>
                    }
                />
              </div>
            </Layout>
        ) || (
          <div ref={printRef}>
            <Layout>
              {loading && (
                  <Layout>
                    <Layout style={{
                      display: 'flex',
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}>
                      <Skeleton.Input style={{width: 175, borderRadius: 8}} active size="small"/>
                      <div style={{display: 'flex', flexDirection: "column", gap: 2}}>
                        <Skeleton.Input style={{width: 100, borderRadius: 8}} active size="small"/>
                        <Skeleton.Input style={{width: 230, borderRadius: 8}} active size="large"/>
                      </div>
                    </Layout>
                  </Layout>
              ) || (
                    <Layout style={{
                      display: 'flex',
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}>
                      <h1 className="text-lg font-bold letter-28 text-black">
                        {forecastType === 'over_history' ? 'WATERFALL REPORT' : 'FORECAST ACCURACY EVOLUTION'}
                      </h1>
                      <ForecastHorizon
                        value={[ moment(horizonRange[0]), moment(horizonRange[1])]}
                        onOpenChange={onOpenChange}
                      />
                    </Layout>
              )}
              <ForecastReport data={responseBody}/>
            </Layout>
          </div>
        )}
      </Scaffold>
  );
};

export default Report;
