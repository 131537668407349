import React from "react";
import { Button, Menu } from "antd";
import axios from "axios";
import { getTemplateByGranularity } from "../../utils/helpers";

const DownloadActions = () => {
  const templates = getTemplateByGranularity()
  const handleDownload = async (template: string, fileName: string) => {
    try {
      const response = await axios({
        url: template,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      // @ts-ignore
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Menu className="border rounded-md">
      <Menu.Item key="download actuals template">
        <Button type="text" onClick={() => handleDownload(templates.actual.path, templates.actual.name)}>
          Actuals template
        </Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="download forecast template">
        <Button type="text" onClick={() => handleDownload(templates.forecast.path, templates.forecast.name)}>
          Forecast template
        </Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="download budget template">
        <Button type="text" onClick={() => handleDownload(templates.budget.path, templates.budget.name)}>
          Budget template
        </Button>
      </Menu.Item>
    </Menu>
  );
};

export default DownloadActions;
