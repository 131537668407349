import React, { useState } from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { FaArrowLeftLong } from "react-icons/fa6";
import Scaffold from 'app/__portions/Scaffold';
import CardHorizon from "./components/CardHorizon";
import ENDPOINTS from "../../Services/endpoints";
import Api from "../../Services";
import CardGranularity from "./components/CardGranularity";
import { GranularitySelectionType } from "../../bloc/atoms";
import LocalStorage from "../../utils/helpers/LocalStorage";
import SettingSideMenu from "../../__portions/SideMenus/SettingSideMenu";
import useDemand from "../../Hooks/useDemand";

import {
  defaultHorizonSelection,
  defaultHorizonUnit,
  getGranularityData,
  getSelectedGranularity,
  handleHorizonList
} from "../../utils/helpers";

const Settings = () => {
  const [, setGranularitySelection] = useRecoilState(GranularitySelectionType);
  const [selected, setSelected] = useState(LocalStorage.getGranularity);
  const [selectedHorizon, setSelectedHorizon] = useState(LocalStorage.getHorizonIndex);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { onHorizonChange } = useDemand();

  const changeGranularity = async (index: string) => {
    setLoading(true)
    const payload = {
      granularity: getSelectedGranularity(index),
      adjustment_lock_days: 5,
    }

    await Api.put(ENDPOINTS.APP_SETTINGS, payload);
    setLoading(false)
  }

  const handleReloadPrevious = async () => {
    await changeGranularity(selected).then(()=> {
      history.goBack();
      setTimeout(() => {
        window.location.reload();
      }, 100);
    })
  };

  const handlerHorizon = async (horizon: string) => {
    setSelectedHorizon(horizon)
    LocalStorage.setHorizonIndex(horizon)

    await onHorizonChange(Number(horizon))
  }

  const handlerGranularity = async (index: string) => {
    setSelected(index);
    LocalStorage.setGranularity(index);
    setGranularitySelection(getSelectedGranularity(index))

    await handlerHorizon(`${defaultHorizonSelection().value}`)
  }

  const onBackPress = () => {
    history.goBack();
  }

  return (
      <Scaffold sideBar={<SettingSideMenu />}>
        <div className="ant-col ant-space-align-center mb-4">
          <Button type='default' className='flex align-center text-primary' shape='round' onClick={onBackPress}>
            <FaArrowLeftLong width={32} height={32} className='mr-2'/>
            Back
          </Button>
        </div>
        <h1 className="text-lg font-bold letter-28 text-black">SETTINGS</h1>

        <div className="bg-white rounded-md p-4 flex flex-nowrap">
          <div className="flex-1 animate-all-500 p-4">
            <span className="text-black font-bold text-lg">
              Granularity
            </span>
            <div className='mt-1 p-2 display-grid'>
              {getGranularityData.map((value) => (
                <CardGranularity
                  key={`granularity_key_${value.index}`}
                  icon={<value.icon size="72px" />}
                  title={value.title}
                  index={value.index}
                  loading={loading}
                  selected={selected === `${value.index}`}
                  onSelect={(index) => handlerGranularity(index)}
                />
              ))}
            </div>

            <div className="mt-3">
              <div className="text-black font-bold text-lg">
                Forecast Horizon
              </div>
              <div className="mt-1 p-2 display-grid">
                {handleHorizonList().map((value) => (
                  <CardHorizon
                    key={`horizon_key_${value}`}
                    title={`${value}`}
                    format={defaultHorizonUnit()}
                    index={`${value}`}
                    selected={selectedHorizon === `${value}`}
                    onSelect={(index) => handlerHorizon(index)}
                  />
                ))}
              </div>
            </div>

            <Button
              type="primary"
              className="items-center rounded-md h-full btn-height min-width-button mt-3"
              onClick={handleReloadPrevious}>Save</Button>
          </div>
        </div>
      </Scaffold>
    );
};

export default Settings;

