import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, message } from "antd";
import { FiUserPlus } from "react-icons/fi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import { UsersState } from "../../../bloc/atoms";
import { IUserTableData } from "../../../typings";
import Api from "../../../Services";
import ENDPOINTS from "../../../Services/endpoints";

import { toUser } from "../../../utils/helpers";

import UsersTable from "./UsersTable";
import CreateUser from "./CreateUser";
import SettingSideMenu from "../../../__portions/SideMenus/SettingSideMenu";
import Scaffold from "../../../__portions/Scaffold";

const Users = () => {
  const history = useHistory();
  const [{ currentUser, hasLoaded, loading, users }, setUser,] = useRecoilState(UsersState);

  const [deleting, setDeleting] = useState(false);

  // const onRowClick = (currentUser:TAny) => {
  //   setUser((currVal) => ({
  //     ...currVal,
  //     currentUser,
  //     showDetails: true,
  //   }));
  // };

  const onEdit = (currentUser = {}) => {
    if (currentUser && Object.keys(currentUser).length) {
      setUser((currVal) => ({
        ...currVal,
        isEdit: true,
        isCreate: false,
        showDetails: false,
        currentUser,
      }));
      return;
    }

    setUser((currVal) => ({
      ...currVal,
      isEdit: true,
      isCreate: false,
      showDetails: true,
    }));
  };

  const onDelete = async (record?: IUserTableData) => {
    const user = record || currentUser;
    setDeleting(true);

    const { data, error } = await Api.delete(
      `${ENDPOINTS.USERS}/${user.key}`,
    );

    if (error) {
      message.error('Failed to delete the user. Please try again');
      setDeleting(false);
      return;
    }

    if (data) {
      const allUsers = users.filter(
        (userData) => userData.key !== user.key,
      );

      const fullName = `${
        currentUser?.info?.firstName || record?.info?.firstName
      } ${currentUser.info?.lastName || record?.info?.lastName}`;

      message.success(
        `${fullName || 'User'} was deleted successfully`,
      );
      setDeleting(false);
      setUser((currVal) => ({
        ...currVal,
        currentUser: {},
        users: allUsers,
        showDetails: false,
      }));
    }
  };

  const fetchUsers = useCallback(async () => {
    setUser((state) => ({ ...state, loading: true }));
    const { data, error } = await Api.get(ENDPOINTS.USERS);

    if (error) {
      setUser((state) => ({
        ...state,
        loading: false,
        hasLoaded: false,
      }));
      return;
    }

    const dataUsers = data.map(toUser);

    setUser((state) => ({
      ...state,
      loading: false,
      hasLoaded: true,
      users: dataUsers,
    }));
  }, [setUser]);

  useEffect(() => {
    if (!hasLoaded) {
      fetchUsers();
    }
  }, [fetchUsers, hasLoaded]);

  const onBackPress = () => {
    history.push('/');
  }

  return (
    <Scaffold sideBar={<SettingSideMenu />}>
      <div className="ant-col ant-space-align-center mb-2">
        <Button type='default' className='flex align-center text-primary' shape='round' onClick={onBackPress}>
          <FaArrowLeftLong width={32} height={32} className='mr-2'/>
          Back
        </Button>
      </div>
      <div className="ant-row ant-row-space-between ant-space-align-center mb-2">
        <h1 className="text-lg font-bold letter-28 text-black">USERS</h1>
        <Button
          type="primary"
          className="items-center rounded-md h-full btn-height px-3"
          style={{ display: 'flex' }}
          onClick={() =>
            setUser((currval) => ({
              ...currval,
              isCreate: true,
              isEdit: false,
            }))
          }
        >
          <FiUserPlus size="16px" className="mr-3" />
          Add User
        </Button>
      </div>

      <div className="bg-white rounded-md p-4 flex flex-nowrap">
        <div className="flex-1 animate-all-500" style={{ paddingRight: '16px' }}>
          <UsersTable
            data={users}
            loading={loading}
            performing={deleting}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </div>
      </div>
      <CreateUser />
    </Scaffold>
  );
};

export default Users;