import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { Button } from "antd";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import SettingSideMenu from "../../../__portions/SideMenus/SettingSideMenu";
import Scaffold from "../../../__portions/Scaffold";
import NotificationTable from "./NotificationTable";

import { NotificationState } from "../../../bloc/atoms";
import Api from "../../../Services";
import ENDPOINTS from "../../../Services/endpoints";

const Notification = () => {
  const history = useHistory();
  const [{loading, data }, setNotificationState] = useRecoilState(NotificationState);

  const loadMoreData = async (page = 1) => {
    if (loading) { return }
    setNotificationState((state) => ({ ...state, loading: true }));
    await Api.get(`${ENDPOINTS.UPLOAD_HISTORY}?offset=8&page=${page}`)
      .then(body => {
        setNotificationState((state) => ({
          ...state,
          items: body.data.items,
          data: body.data.results,
        }));
        setNotificationState((state) => ({
          ...state,
          loading: false,
        }));
      })
      .catch(() => {
        setNotificationState((state) => ({
          ...state,
          loading: false,
        }));
      });
  };

  useEffect(() => {
    loadMoreData();
  }, []);

  const onBackPress = () => {
    history.push('/');
  }

  return (
    <Scaffold sideBar={<SettingSideMenu />}>
      <div className="ant-col ant-space-align-center mb-4">
        <Button type='default' className='flex align-center text-primary' shape='round' onClick={onBackPress}>
          <FaArrowLeftLong width={32} height={32} className='mr-2'/>
          Back
        </Button>
      </div>
      <h1 className="text-lg font-bold letter-28 text-black">NOTIFICATIONS</h1>

      <div className="bg-white rounded-md p-4 flex flex-nowrap">
        <div className="flex-1 animate-all-500" style={{ paddingRight: '16px' }}>
          <NotificationTable loading={loading} data={data}/>
        </div>
      </div>
    </Scaffold>
  )
}

export default Notification;