import React from 'react';
import { Progress, Select } from 'antd';
import { useRecoilState } from 'recoil';
import { DemandPlanningState } from 'app/bloc/atoms';
import {
  IModel,
  TPerformanceCheckers,
  TPredictionModel,
} from 'app/typings';
import SelectInput from 'app/__portions/SelectInput';
import {
  PERFORMANCE_CHOICES,
  USER_ROLES,
} from 'app/utils/helpers/constants';
import RestrictedTo from 'app/__portions/RestrictedTo';
import DropdownArrow from 'app/__portions/_drawables/DropdownArrow';

const { Option } = Select;

interface IProps {
  onPredictionChange?: (predictionModel: TPredictionModel) => void;
}

const ModalCustomization = ({ onPredictionChange }: IProps) => {
  const [{ data, performance }, setState] = useRecoilState(
    DemandPlanningState,
  );

  const accuracy = data?.model?.accuracy;
  const isAccurate = accuracy && accuracy > 0;

  const onPerformanceChange = (performance: TPerformanceCheckers) => {
    const usersConsensus: Record<TPerformanceCheckers, IModel[]> = {
      Sales: data.consensus.salesConsensus,
      Planner: data.consensus.plannerConsensus,
      Machine: data.models as IModel[],
    };

    const currentPerformance = usersConsensus[performance]?.find(
      (consensus) => consensus?.name === data.model?.name,
    );

    setState((state) => ({
      ...state,
      performance,
      data: { ...state.data, model: currentPerformance },
    }));
  };

  return (
    <div className="w-full">
      <div className="font-bold">Prediction Details</div>

      <div className="ant-row ant-row-space-between ant-row-center">
        <RestrictedTo to={[USER_ROLES.Sales.value]}>
          <div className="ant-col justify-between items-center">
            <div>Performances</div>
            <SelectInput
              className="rounded-sm select-options"
              defaultValue={performance}
              onChange={onPerformanceChange}
            >
              {Object.values(PERFORMANCE_CHOICES).map(({ name, value }) => (
                  <Option key={value} value={value}>
                    {name}
                  </Option>
                ),
              )}
            </SelectInput>
          </div>
        </RestrictedTo>
        <div className="border-r"/>
        <div className="ant-col ant-space-align-center">
          <div>Accuracy (wMAPE)</div>
          <Progress
            type={isAccurate ? 'dashboard' : 'circle'}
            format={(percent) => {
              if (!isAccurate) {
                return `${accuracy}`;
              }
              return `${percent}%`;
            }}
            percent={isAccurate ? accuracy : 100}
            width={80}
            strokeWidth={(isAccurate && 15) || undefined}
            status={isAccurate ? 'normal' : 'exception'}
          />
        </div>
        <div className="border-r"/>
        <div className="ant-col ant-space-align-center">
          <div>Error Measure (RMSE)</div>
          <div className="text-blue font-bold">{data.model?.RMSE}</div>
        </div>
        <div className="border-r"/>
        <div className="ant-col ant-space-align-center">
          <div>Best Prediction Method</div>

          <Select
            defaultValue={data.model?.name}
            bordered={false}
            className="prediction-select"
            // @ts-ignore
            suffixIcon={DropdownArrow}
            onChange={onPredictionChange}
            disabled={[
              PERFORMANCE_CHOICES.Sales.value,
              PERFORMANCE_CHOICES.Planner.value,
            ].includes(performance)}
          >
            {data.models?.map((model, index) => (
              <Option
                key={`${model.name}_${index.toFixed()}`}
                value={model?.name}
              >
                {model?.description}({model?.name})
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

ModalCustomization.defaultProps = {
  onPredictionChange: () => null,
};

export default ModalCustomization;
